import { SingleSelection } from '../../../../components';
import { useTranslation } from '../../../../hooks';
import { Currency } from '../../../../views/ProgramAgentManagement/FeeAdjustmentRate/types';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const FeeAdjustmentRateCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, t } = useTranslation('feeAdjustmentRate');

  const Enum = {
    [t('all')]: Currency.All,
    [t('hkd')]: Currency.HKD,
    [t('nonHkd')]: Currency.NonHKD,
  };

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('currency') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={Enum}
      nameFn={(name) => name}
      isNoSorting
    />
  );
};

export default FeeAdjustmentRateCurrencySingleSelection;
