import React from 'react';
import ActiveTabPicker from '../ActiveTabPicker';
import { useTranslation } from '../../../../../../../hooks';
import { ActiveTabs, CountryRegion, Currency } from '../../../../types';
import Filter from '../../../FilterCard/Filter';
import FeeAdjustmentRateCountrySingleSelection from '../../../../../../../common/filterTable/components/Filters/FeeAdjustmentRateCountrySingleSelection';
import FeeAdjustmentRateCurrencySingleSelection from '../../../../../../../common/filterTable/components/Filters/FeeAdjustmentRateCurrencySingleSelection';
import { useAppSelector } from '../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../reducer/mediaSlice';

interface IProps {
  countryRegion: string;
  setCountryRegion: (countryRegion: string) => void;
  currency: string;
  setCurrency: (currency: string) => void;
  setTransactionType: (transactionType: number) => void;
}

const ResultFilters = (props: IProps) => {
  const { countryRegion, setCountryRegion, currency, setCurrency, setTransactionType } = props;

  const { t } = useTranslation('feeAdjustmentRate');
  const isMobile = useAppSelector(selectIsMobileView);

  const tabsInfo = [
    {
      id: ActiveTabs.Purchase,
      name: t('purchase'),
    },
    {
      id: ActiveTabs.Refund,
      name: t('refund'),
    },
    {
      id: ActiveTabs.Withdraw,
      name: t('withdraw'),
    },
  ];
  return (
    <div
      style={{
        ...(!isMobile && {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }),
      }}
    >
      {/* Left */}
      <div style={{ width: isMobile ? '100%' : '30%' }}>
        <ActiveTabPicker
          tabsInfo={tabsInfo}
          onClickTab={(tabId: string) => setTransactionType(Number(tabId))}
        />
      </div>

      {/* Right */}
      <div
        style={{
          width: isMobile ? '100%' : '50%',
          ...(!isMobile && { display: 'flex', alignItems: 'center' }),
        }}
      >
        <div style={{ width: isMobile ? '100%' : '50%', marginRight: '16px' }}>
          <Filter title={t('countryRegion')}>
            <FeeAdjustmentRateCountrySingleSelection
              value={countryRegion}
              onChange={(e) => setCountryRegion(e.target.value)}
              onClear={() => setCountryRegion(CountryRegion.All)}
            />
          </Filter>
        </div>

        <div style={{ width: isMobile ? '100%' : '50%' }}>
          <Filter title={t('currency')}>
            <FeeAdjustmentRateCurrencySingleSelection
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              onClear={() => setCurrency(Currency.All)}
            />
          </Filter>
        </div>
      </div>
    </div>
  );
};

export default ResultFilters;
