import { utcToZonedTime } from 'date-fns-tz';
import addHours from 'date-fns/addHours';
import { useCallback, useEffect, useState } from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { Row, RowContent, RowHeader } from '../../../common/dialogs/layout/contentLayout';
import TimePicker from '../../../components/DatePicker';
import { Box } from '../../../components/MuiGenerals';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useAgentProgramCurrency from '../../../hooks/useAgentProgramCurrency';
import useLoading from '../../../hooks/useLoading';
import { AgentCurrency } from '../../../reducer/agentCurrencySlice';
import { AgentProgramCurrency } from '../../../reducer/agentProgramCurrencySlice';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { selectProfile } from '../../../reducer/profileSlice';
import { toDisplayTime, toTime } from '../../../utils';
import CancelButton from './components/CancelButton';
import ConfirmButton from './components/ConfirmButton';
import { CreateButtonView } from './components/CreateButtonView';
import CurrentExchangeRateTextField from './components/CurrentExchangeRateTextField';
import EditableItem from './components/EditableItem';
import EditButton from './components/EditButton';
import FromCurrencySingleSelection from './components/FromCurrencySingleSelection';
import NextExchangeRateTextField from './components/NextExchangeRateTextField';
import ToCurrencySingleSelection from './components/ToCurrencySingleSelection';
import { fetchExchangeRate } from './helpers/fetchExchangeRate';
import { getIsExceedDecimalDigitLimit } from './helpers/getIsExceedDecimalDigitLimit';
import { getIsNoChangeForUpdate } from './helpers/getIsNoChangeForUpdate';
import { handleExchangeRateUpdate } from './helpers/handleExchangeRateUpdate';
import useExchangeRateValidation from './helpers/useExchangeRateValidation';
import { CreateExchangeRateFields, PageMode } from './types';

// const hoursOffSetFromGMT0 = new Date().getTimezoneOffset() / 60;
// const currentDateGMT0 = addHours(new Date(), hoursOffSetFromGMT0);

const ExchangeRate = () => {
  const currentDate = new Date();

  const initFields: CreateExchangeRateFields = {
    currentExchangeRate: '',
    nextExchangeRate: '',
    startFrom: null,
  };

  const [mode, setMode] = useState<PageMode | ''>();
  const [fromCurrencyData, setFromCurrencyData] = useState<AgentCurrency>();
  const [toCurrencyData, setToCurrencyData] = useState<AgentProgramCurrency>();

  const [fields, setFields] = useState<CreateExchangeRateFields>(initFields);
  const [isInfinity, setIsInfinity] = useState(false);
  const [exchangeRateApiRes, setExchangeRateApiRes] = useState<any>();
  // extra set of data for isNoChange checking, since api nextExchangeRate will return '0' not null, and startFrom is never null
  const [fieldsForRead, setFieldsForRead] = useState<CreateExchangeRateFields>(initFields);
  const [isInfinityChanged, setIsInfinityChanged] = useState(false);

  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();
  const { t, tc } = useTranslation('exchangeRate');
  const { hasPermission } = usePermission();
  const { AgentProgramCurrencyEnum } = useAgentProgramCurrency();
  const { validate } = useExchangeRateValidation({ fields, isInfinity });

  const isMobile = useAppSelector(selectIsMobileView);
  const { timezone } = useAppSelector(selectProfile);

  const isEditable = mode === PageMode.EDIT || mode === PageMode.CREATE;
  const isReadMode = mode === PageMode.READ;
  const isDataView = mode === PageMode.READ || mode === PageMode.CREATE || mode === PageMode.EDIT;
  const isHaveBothCurrencySet =
    fromCurrencyData &&
    fromCurrencyData.currency &&
    toCurrencyData &&
    toCurrencyData.creditTokenName;

  const isHaveEditPermission = hasPermission(AgentPortalFeatureCode.Setting.ExchangeRate.Edit);

  const isHaveCreatePermission = hasPermission(
    AgentPortalFeatureCode.Setting.ExchangeRate.CreateRecord
  );

  const nextExchangeRateValue = isInfinity ? '' : fields.nextExchangeRate;

  const createDisplayTime = (time: Date | null) => {
    if (!time) return null;

    return toDisplayTime(time);
  };

  const handleCancel = () => {
    if (mode === PageMode.CREATE) {
      setMode(PageMode.CREATE_BUTTON);
      setFields(initFields);
    }

    if (mode === PageMode.EDIT) {
      setMode(PageMode.READ);
      setExchangeRateApiRes(null);
    }
  };

  const handleConfirm = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    // Stop flow when no field changes
    const isNoChangeForUpdate = getIsNoChangeForUpdate(
      fieldsForRead,
      isInfinityChanged,
      exchangeRateApiRes
    );

    if (isNoChangeForUpdate) {
      return alerting('error', t('noChangeAlert'));
    }

    // CREATE / EDIT api call
    showLoading('createOrEditExchangeRate');
    const res = await handleExchangeRateUpdate({
      fromCurrency: fromCurrencyData?.currency || '',
      toCurrency: toCurrencyData?.creditTokenName || '',
      currentExchangeRate: fields.currentExchangeRate,
      nextExchangeRate: fields.nextExchangeRate,
      nextExchangeRateTime: fields.startFrom,
      isInfinity: isInfinity,
    });
    hideLoading('createOrEditExchangeRate');

    if (!res) return;

    alerting('success', t('editSuccessAlert'));
    setMode(PageMode.READ);
    setExchangeRateApiRes(null);
    setFields(initFields);
    setIsInfinity(false);
    setIsInfinityChanged(false);
  };

  const handleReadModeEffect = useCallback(async () => {
    if (!isHaveBothCurrencySet || exchangeRateApiRes) return;

    setIsInfinity(false);
    setIsInfinityChanged(false);

    // fetch exchange rate api
    showLoading('getExchangeRate');
    const res = await fetchExchangeRate(fromCurrencyData.currency, toCurrencyData.creditTokenName);
    hideLoading('getExchangeRate');

    if (!res) return;

    setExchangeRateApiRes(res);
    const isHaveRecord = Number(res.currentExchangeRate) !== 0;

    if (!isHaveRecord) {
      return setMode(PageMode.CREATE_BUTTON);
    }

    setFields((fields) => ({
      ...fields,
      currentExchangeRate: res.currentExchangeRate,
      // nextExchangeRate: res.nextExchangeRate,
      // startFrom: res.StartFrom,
      nextExchangeRate: Number(res.nextExchangeRate) === 0 ? '' : res.nextExchangeRate,
      startFrom:
        Number(res.nextExchangeRate) === 0 ? null : getTimeWithPortalTimezone(res.StartFrom),
    }));
    setFieldsForRead((fields) => ({
      ...fields,
      currentExchangeRate: res.currentExchangeRate,
      nextExchangeRate: res.nextExchangeRate,
      startFrom: res.StartFrom,
    }));

    setMode(PageMode.READ);
  }, [isHaveBothCurrencySet, exchangeRateApiRes, fromCurrencyData, toCurrencyData]);

  useEffect(() => {
    if (!isHaveBothCurrencySet) return;

    handleReadModeEffect();
  }, [mode, isHaveBothCurrencySet, exchangeRateApiRes, handleReadModeEffect]);

  // Set default value as first item if only have one option in ToCurrency
  useEffect(() => {
    if (!AgentProgramCurrencyEnum) return;

    if (Object.keys(AgentProgramCurrencyEnum).length === 1) {
      const firstKey = Object.keys(AgentProgramCurrencyEnum)[0];
      const firstItem = AgentProgramCurrencyEnum[firstKey];

      if (!toCurrencyData) {
        setToCurrencyData(JSON.parse(firstItem));
      }
    }
  }, [AgentProgramCurrencyEnum, toCurrencyData]);

  const getNoZoneTime = (time: any) => {
    return utcToZonedTime(time, 'UTC');
  };

  const getTimeWithPortalTimezone = (timeValue: any) => {
    // // normalize value
    const time = toTime(timeValue);
    if (!time) return null;

    const portalTimezone = Number(timezone) || 0;
    const timeWithPortalTimezone = addHours(time, portalTimezone);

    return getNoZoneTime(timeWithPortalTimezone);
  };

  return (
    <Box style={{ padding: isMobile ? '0 32px' : '0' }}>
      {/* Currency Filter */}
      <div style={{ maxWidth: '50%' }}>
        <Row sx={{ marginBottom: '32px' }}>
          <RowHeader>{t('fromCurrency')}</RowHeader>
          <RowContent>
            <FromCurrencySingleSelection
              value={fromCurrencyData}
              onChange={(e) => {
                setFromCurrencyData(JSON.parse(e.target.value));
                setFields(initFields);
              }}
              onClear={() => {
                setFromCurrencyData({} as AgentCurrency);
                setMode('');
                setFields(initFields);
                setExchangeRateApiRes(null);
                setIsInfinity(false);
              }}
            />
          </RowContent>
        </Row>

        <Row>
          <RowHeader>{t('toCurrency')}</RowHeader>
          <RowContent>
            <ToCurrencySingleSelection
              value={toCurrencyData}
              onChange={(e) => {
                setToCurrencyData(JSON.parse(e.target.value));
                setFields(initFields);
              }}
              onClear={() => {
                setToCurrencyData({} as AgentProgramCurrency);
                setMode('');
                setFields(initFields);
                setExchangeRateApiRes(null);
                setIsInfinity(false);
              }}
            />
          </RowContent>
        </Row>
      </div>

      {isHaveBothCurrencySet && (
        <>
          {mode === PageMode.CREATE_BUTTON && (
            <CreateButtonView
              onClick={() => {
                setMode(PageMode.CREATE);
              }}
              isHavePermission={isHaveCreatePermission}
            />
          )}

          {isDataView && (
            <div>
              {/* Current Exchange Rate */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <EditableItem
                  title={t('currentExchangeRate')}
                  value={fields?.currentExchangeRate}
                  isEditable={isEditable}
                >
                  <CurrentExchangeRateTextField
                    value={fields.currentExchangeRate}
                    onChange={(e) => {
                      if (getIsExceedDecimalDigitLimit(e.target.value)) return;

                      setFields((fields) => ({ ...fields, currentExchangeRate: e.target.value }));
                      setFieldsForRead((fields) => ({
                        ...fields,
                        currentExchangeRate: e.target.value,
                      }));
                    }}
                  />
                </EditableItem>

                {/* Infinity checkbox */}
                {isEditable && (
                  <div style={{ display: 'flex', paddingLeft: '32px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isInfinity}
                          onChange={() => {
                            setIsInfinity(!isInfinity);
                            setFields((fields) => ({
                              ...fields,
                              nextExchangeRate: '',
                              startFrom: null,
                            }));
                            setFieldsForRead((fields) => ({
                              ...fields,
                              nextExchangeRate: '',
                              startFrom: null,
                            }));
                            setIsInfinityChanged((isInfinityChanged) => !isInfinityChanged);
                          }}
                        />
                      }
                      label={t('infinity')}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  ...(isMobile && {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: '100%',
                  }),
                }}
              >
                {/* Next Exchange Rate */}
                <EditableItem
                  title={t('nextExchangeRate')}
                  value={Number(fields?.nextExchangeRate) === 0 ? '' : fields?.nextExchangeRate}
                  isEditable={isEditable}
                >
                  <NextExchangeRateTextField
                    value={nextExchangeRateValue}
                    onChange={(e) => {
                      if (getIsExceedDecimalDigitLimit(e.target.value)) return;

                      setFields((fields) => ({
                        ...fields,
                        nextExchangeRate: e.target.value,
                      }));
                      setFieldsForRead((fields) => ({
                        ...fields,
                        nextExchangeRate: e.target.value === '' ? '0' : e.target.value,
                      }));
                    }}
                    isDisabled={isInfinity}
                  />
                </EditableItem>

                {/* Start From */}
                <EditableItem
                  title={t('startFrom')}
                  value={
                    Number(fields.nextExchangeRate) === 0
                      ? ''
                      : createDisplayTime(exchangeRateApiRes?.StartFrom)
                  }
                  isEditable={isEditable}
                >
                  <TimePicker
                    label={tc('phSelection', { fieldName: t('startFrom') })}
                    type={'dateTime'}
                    timeValue={fields.startFrom}
                    setTimeValue={(value: any) => {
                      setFields((fields) => ({ ...fields, startFrom: value }));
                      setFieldsForRead((fields) => ({ ...fields, startFrom: value }));
                    }}
                    disabled={isInfinity}
                    disablePast={true}
                    // cant select time before currentDate
                    minEndTime={currentDate}
                  />
                </EditableItem>
              </div>

              {/* Action Row */}
              <div style={{ marginTop: '80px', paddingLeft: '32px' }}>
                {isHaveEditPermission && isReadMode && (
                  <EditButton onClick={() => setMode(PageMode.EDIT)} />
                )}

                {isEditable && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '48px' }}>
                      <ConfirmButton onClick={handleConfirm} />
                    </div>
                    <CancelButton onClick={handleCancel} />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default ExchangeRate;
